<template>
  <div>
    <a-modal v-model="visible" width="750px" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{form.id ? '编辑产品信息' : '新增产品信息' }}</div>
      <div>
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4, md: 8 }"
          :wrapper-col="{ span: 20, md: 16 }">
          <a-row :gutter="12">
            <a-divider orientation="left" id="basic-information">
              基本信息
            </a-divider>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="types" label="新增类型">
                <a-select placeholder="选择新增类型" v-model="form.types" style="width: 100%">
                  <a-select-option value="product">
                    产品
                  </a-select-option>
                  <a-select-option value="material">
                    原料
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="barcode" label="条形码">
                <a-input v-model="form.barcode" />
                <!-- <a-input-search v-model="form.barcode">
                  <a-button slot="enterButton" type="primary" @click.native="getCode">生成条形码</a-button>
                </a-input-search> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="number" label="产品编号">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="name" label="产品名称">
                <a-input v-model="form.name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="spec" label="规格">
                <a-input v-model="form.spec" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="product_brand" label="品牌">
                <a-input v-model="form.product_brand" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="category" label="分类">
                <a-select v-model.number="form.category" style="width: 100%" :allowClear="true">
                  <a-select-option
                    v-for="item of classificationItems"
                    :key="item.id"
                    :value="item.id">{{ item.name }}
                  </a-select-option>
                </a-select>
                <a-button
                  type="primary"
                  @click="onAdd">
                  <a-icon type="plus" /> 新增分类
                </a-button>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="unit" label="单位">
                <a-select v-model.number="form.unit" :allowClear="true">
                  <a-select-option
                    v-for="item of unitItems"
                    :key="item.id"
                    :value="item.id">{{ item.name }}
                  </a-select-option>
                </a-select>
                <a-button
                  type="primary"
                  @click="onAdd1">
                  <a-icon type="plus" /> 新增单位
                </a-button>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="standards" label="执行标准">
                <a-input v-model="form.standards" />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="24" :md="12">
              <a-form-model-item prop="enable_shelf_life" label="启用保质期">
                <a-switch
                  checked-children="启用"
                  un-checked-children="禁用"
                  v-model="form.enable_shelf_life"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="shelf_life_days" label="保质期天数">
                <a-input v-model="form.shelf_life_days" suffix="天" />
              </a-form-model-item>
            </a-col> -->
            <a-col :span="24" :md="12">
              <a-form-model-item
                prop="shelf_life_warning_days"
                label="保质期预警天数">
                <a-input-number style="width: 180px; margin-right: 20px" v-model="form.shelf_life_warning_days" /><span>天</span>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="enable_batch_control" label="启用批次控制">
                <a-switch
                  checked-children="启用"
                  un-checked-children="禁用"
                  v-model="form.enable_batch_control"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="shelf_life_days" label="保质期">
                <a-input-number style="width: 150px" v-model="form.shelf_life_days" />
                <a-select placeholder="选择保质期单位" v-model="form.period_unit" style="width: 80px">
                  <a-select-option value="天">
                    天
                  </a-select-option>
                  <a-select-option value="月">
                    月
                  </a-select-option>
                  <a-select-option value="年">
                    年
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="is_active" label="状态">
                <a-select v-model="form.is_active" style="width: 100%;">
                  <a-select-option :value="true">激活</a-select-option>
                  <a-select-option :value="false">冻结</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col v-if="form.id" :span="24" :md="12">
              <a-form-model-item prop="remark" label="关联原材料">
                <a-select mode="multiple" v-model="form.association">
                  <a-select-option v-for="(val, index) in listItem" :key="index" :value="val.id">
                    {{ val.name }}({{val.id}})
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="remark" label="备注">
                <a-textarea  v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-divider orientation="left" id="price-management">
              价格管理
            </a-divider>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="purchase_price" label="采购价(元)">
                <a-input-number v-model="form.purchase_price" style="width: 100%"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="retail_price" label="零售价(元)">
                <a-input-number v-model="form.retail_price" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="level_price1" label="等级价一(元)">
                <a-input-number v-model="form.level_price1" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="level_price2" label="等级价二(元)">
                <a-input-number v-model="form.level_price2" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="level_price3" label="等级价三(元)">
                <a-input-number v-model="form.level_price3" style="width: 100%" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-divider orientation="left" id="graphic-information">
              图文信息
            </a-divider>
            <a-col :span="24" :md="24">
              <Upload :ID_image="form.images" :count="5" @handleChange="handleChange" />
              <!-- <a-upload
                :action="uploadUrl"
                list-type="picture-card"
                :headers="{ 'X-CSRFToken': Cookies.get('csrftoken'), Authorization: 'Bearer ' + Cookies.get('access') }"
                :file-list="form.images"
                @preview="handlePreview"
                @change="handleChange"
                :before-upload="beforeUpload"
                name="file">
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text"></div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal> -->
            </a-col>
            <a-col :span="24" :md="24">
              <a-textarea
                placeholder="产品详细介绍"
                :rows="4"
                v-model="form.description"
              />
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-divider orientation="left" id="beginning-inventory">
              期初库存
            </a-divider>
            <a-col :span="24" :md="24">
              <a-table
                rowkey="id"
                :columns="columns"
                :data-source="warehouseItems"
                :loading="loading"
                :pagination="false"
                style="width: 100%" >
                <div slot="initial_quantity" slot-scope="value, item">
                  <div v-if="!!form.enable_batch_control">
                    <a-input :value="item.initial_quantity" disabled style="width:75%;" />
                    <a-button @click="chooseBatch(item)">批</a-button>
                  </div>
                  <a-input-number v-else
                    :value="item.initial_quantity"
                    :min="1"
                    @change="(value) => changeQuantity(value, item)" />
                </div>
              </a-table>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-divider orientation="left" id="inventory-warning">
              库存预警
            </a-divider>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="enable_inventory_warning" label="启用库存警告">
                <a-switch
                  checked-children="启用"
                  un-checked-children="禁用"
                  v-model="form.enable_inventory_warning"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="!!form.enable_inventory_warning">
              <a-form-model-item prop="inventory_upper" label="库存上限">
                <a-input-number v-model="form.inventory_upper" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="!!form.enable_inventory_warning">
              <a-form-model-item prop="inventory_lower" label="库存下限">
                <a-input-number v-model="form.inventory_lower" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="24" :md="12">
              <a-form-model-item
                prop="inventory_warning_upper_limit"
                label="库存预警上限">
                <a-input-number
                  v-model="form.inventory_warning_upper_limit"
                  style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item
                prop="inventory_warning_lower_limit"
                label="库存预警下限">
                <a-input-number
                  v-model="form.inventory_warning_lower_limit"
                  style="width: 100%"/>
              </a-form-model-item>
            </a-col> -->
          </a-row>
        </a-form-model>
      </div>
      <!-- 批次 -->
      <a-modal
        :title="batchTitle"
        v-model="batchVisible"
        width="750px"
        cancelText="关闭"
        :maskClosable="false"
        @cancel="batchVisible=false"
        @ok="confirmChoosed">
        <div style="margin-bottom: 16px">
          <a-button type="primary" icon="plus" style="margin: 0 8px;" @click="addLine">添加</a-button>
        </div>
        <a-table
          rowkey="id"
          :columns="columnsBatch"
          :data-source="batchItems"
          :pagination="false"
          style="width: 100%" >
          <div slot="initial_quantity" slot-scope="value, item">
            <a-input-number
              :value="item.initial_quantity"
              :min="1"
              @change="(value) => changeQuantityBatch(value, item, 'initial_quantity')" />
          </div>
          <div slot="number" slot-scope="value, item">
            <a-input
              :value="item.number"
              @change="(e) => changeQuantityBatch(e, item, 'number')" />
          </div>
          <div slot="production_date" slot-scope="value, item">
            <a-date-picker
              :value="item.production_date"
              valueFormat="YYYY-MM-DD"
              @change="(value) => changeQuantityBatch(value, item, 'production_date')" />
          </div>
          <div slot="action" slot-scope="value,item">
            <a-button icon="minus" @click="removeLine(item)"></a-button>
          </div>
        </a-table>
      </a-modal>
      <!-- 分类 -->
      <a-modal v-model="categoryVisible" title="新增产品分类" :maskClosable="false" @cancel="categoryVisible = false" @ok="addClass">
        <div>
          <a-form-model ref="categoryForm" :model="categoryForm" :rules="categoryRules" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
            <a-form-model-item prop="name" label="分类名称">
              <a-input v-model="categoryForm.name" />
            </a-form-model-item>
            <a-form-model-item prop="remark" label="备注">
              <a-input v-model="categoryForm.remark" allowClear />
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-modal>
      <!-- 单位 -->
      <a-modal v-model="unitVisible" title="新增产品单位" :maskClosable="false" @cancel="unitVisible = false" @ok="addUnit">
        <div>
          <a-form-model ref="unitForm" :model="unitForm" :rules="unitRules" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
            <a-form-model-item prop="name" label="单位名称">
              <a-input v-model="unitForm.name" />
            </a-form-model-item>
            <a-form-model-item prop="remark" label="备注">
              <a-input v-model="unitForm.remark" allowClear />
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-modal>
    </a-modal>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'
  import Upload from '../../../components/Upload/index'
  import { goodsInformationCreate, goodsInformationUpdate, goodsClassificationCreate, goodsUnitCreate } from '@/api/goods'
  export default {
    name: 'FormModal',
    props: ['visible', 'form', 'warehouseItems', 'classificationItems', 'unitItems', 'listItem'],
    model: { prop: 'visible', event: 'cancel' },
    components: {
      Upload
    },
    data() {
      return {
        categoryForm: {},
        unitForm: {},
        uploadUrl: process.env.VUE_APP_NEW_URL + '/comapi/UploadApi',
        Cookies,
        batchTitle: '管理批次',
        batchVisible: false,
        unitVisible: false,
        loading: false,
        columns: [
          {
            title: "仓库",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "初始库存",
            dataIndex: "initial_quantity",
            key: "initial_quantity",
            scopedSlots: { customRender: "initial_quantity" },
          },
        ],
        isKeepAdd: false,
        categoryRules: {
          name: [{ required: true, message: '请输入分类名称', trigger: 'blur' },
            { max: 64, message: "超出最大长度 (64)", trigger: "blur" },]
        },
        unitRules: {
          name: [{ required: true, message: '请输入单位名称', trigger: 'blur' },
            { max: 64, message: "超出最大长度 (64)", trigger: "blur" },]
        },
        categoryVisible: false,
        rules: {
          types: [
            { required: true, message: "请选择新增类型", trigger: "change" }
          ],
          name: [
            { required: true, message: "请输入产品名称", trigger: "blur" },
          ],
          number: [
            { required: true, message: "请输入产品编号", trigger: "blur" },
          ],
          shelf_life_days: [
            { required: true, message: "请输入保质期", trigger: "blur" },
          ],
          period_unit: [
            { required: true, message: "请选择保质期单位", trigger: "change" }
          ]
        },
        previewVisible: false,
        previewImage: "",
        columnsBatch: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
          },
          {
            title: "编号",
            dataIndex: "number",
            key: "number",
            scopedSlots: { customRender: "number" },
          },
          {
            title: "初始库存",
            dataIndex: "initial_quantity",
            key: "initial_quantity",
            scopedSlots: { customRender: "initial_quantity" },
          },
          {
            title: "生产日期",
            dataIndex: "production_date",
            key: "production_date",
            scopedSlots: { customRender: "production_date" },
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: '80px'
          },
        ],
        curWarehouse: {},
        batchItems: []
      };
    },
    methods: {
      onAdd() {
        this.categoryVisible = true;
        this.categoryForm = {}
      },
      onAdd1() {
        this.unitVisible = true;
        this.unitForm = {}
      },
      addClass() {
        this.$refs.categoryForm.validate(valid => {
          if (valid) {
            goodsClassificationCreate(this.categoryForm).then(data => {
              this.$message.success('新增成功');
              this.categoryVisible = false;
              this.$emit('getClassInfo')
            });
          }
        });
      },
      addUnit() {
        this.$refs.unitForm.validate(valid => {
          if (valid) {
            goodsUnitCreate(this.unitForm).then(data => {
              this.$message.success('新增成功');
              this.unitVisible = false;
              this.$emit('getUnitInfo')
            });
          }
        });
      },
      chooseBatch(item) {
        this.batchTitle = '管理批次-' + item.name;
        this.curWarehouse = item;
        if (item.batch_items) {
          this.batchItems = item.batch_items;
        } else {
          this.batchItems = [
            {
              key: 'uni1',
              number: '',
              initial_quantity: 1,
              production_date: null,
            }
          ];
        }
        this.batchVisible = true;
      },
      confirmChoosed() {
        let ifHasEmpty = false;
        let sumAmount = 0;
        this.batchItems.map(item => {
          sumAmount+=item.initial_quantity
          if (!item.number || !item.initial_quantity || !item.production_date) {
            ifHasEmpty = true;
          }
        })
        if (ifHasEmpty) {
            this.$message.warn('请将批次信息填写完整!');
            return
          }
        let tmp = {...this.curWarehouse, ...{ batch_items: this.batchItems, initial_quantity: sumAmount }}
        this.warehouseItems = this.$functions.replaceItem(this.warehouseItems, tmp);
        this.batchVisible = false;
      },
      addLine() {
        const { batchItems } = this;
        const newData = {
          key: 'uni' + batchItems.length + 1,
          number: '',
          initial_quantity: 1,
          production_date: null,
        };
        this.batchItems = [...batchItems, newData];
      },
      removeLine(item) {
        this.batchItems = this.$functions.removeItemBatch(this.batchItems, item);
      },
      changeQuantity(value, item) {
        item['initial_quantity'] = value || 0;
        this.warehouseItems = this.$functions.replaceItem(this.warehouseItems, item);
      },
      changeQuantityBatch(e, item, pro) {
        if (pro === 'number') {
          item[pro] = e.target && e.target.value;
        } else {
          item[pro] = e;
        }
        this.batchItems[item.key - 1] = item;
      },
      handleChange(val) {
        this.$set(this.form,'images',val);
        this.$forceUpdate();
      },
      beforeUpload() {},
      handleCancel() {
        this.previewVisible = false;
      },
      confirm() {
        console.log(this.form.images);
        let images = this.form.images && this.form.images.filter((item) => (item.response && item.response.code === 200) || (item.status && item.status === 'done'))
            .map((item) => {
              if (item.url) return item.url;
              return item.response.data.file_url;
            });
        let inventory_items = this.warehouseItems && this.warehouseItems.map(item => {
          return {
            warehouse: item.id,
            initial_quantity: item.initial_quantity,
            batch_items: this.form.enable_batch_control ? item.batch_items : []
          }
        })
        this.form.shelf_life_days = this.form.shelf_life_days * 1
        this.form.images = images
        console.log(images);
        let formatData = {
          ...this.form,
          ...{
            // goods_images: image_items,
            inventory_items
          }
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            this.loading = true;
            let func = this.form.id ? goodsInformationUpdate : goodsInformationCreate;
            func(formatData).then(data => {
              this.$message.success(this.form.id ? '修改成功' : '新增成功');
              this.$emit(this.form.id ? 'update' : 'create', data);
              this.cancel();
            }).finally(() => {
              this.loading = false;
            });
          }
        });
      },
      cancel() {
        this.$emit('cancel', false);
        this.$refs.form.resetFields();
      },
    },
    watch: {
      'form.association': {
        handler(newVal) {
          if (newVal == null) {
            this.$set(this.form,'association',[]);
          }
        },
        deep: true
      },
    }
  }
</script>

<style scoped>
</style>
